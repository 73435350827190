import ReplaceMenuProvider from "bpmn-js/lib/features/popup-menu/ReplaceMenuProvider"
import { isAny, is } from "bpmn-js/lib/features/modeling/util/ModelingUtil"

export default class CustomReplaceMenuProvider extends ReplaceMenuProvider {
  constructor(
    bpmnFactory,
    popupMenu,
    modeling,
    moddle,
    bpmnReplace,
    rules,
    translate
  ) {
    super(
      bpmnFactory,
      popupMenu,
      modeling,
      moddle,
      bpmnReplace,
      rules,
      translate
    )
  }

  getPopupMenuEntries(element) {
    const originalEntries = super.getPopupMenuEntries(element)

    if (isAny(element, ["bpmn:Task"])) {
      return this.createTaskEntries(element)
    }

    if (isAny(element, ["bpmn:StartEvent", "bpmn:EndEvent"])) {
      return {}
    }

    if (is(element, "bpmn:ExclusiveGateway")) {
      return this.createGatewayEntry(
        element,
        "bpmn:ParallelGateway",
        "Parallel Gateway",
        "bpmn-icon-gateway-parallel"
      )
    }

    if (is(element, "bpmn:ParallelGateway")) {
      return this.createGatewayEntry(
        element,
        "bpmn:ExclusiveGateway",
        "Exclusive Gateway",
        "bpmn-icon-gateway-exclusive"
      )
    }

    return originalEntries
  }

  createTaskEntries(element) {
    const taskTypes = [
      { label: "Offer", type: "offer" },
      // {label: "Cross-Sell", type: "cross_sell"},
      { label: "Quiz", type: "quiz" },
      { label: "Checkout", type: "checkout" },
      // {label: "Popup", type: "popup"},
      // {label: "Metrics", type: "metrics"},
    ]
    const entries = {}

    taskTypes.forEach(task => {
      const id = `${task.type}-task`
      entries[id] = {
        id,
        label: task.label,
        className: "bpmn-icon-task",
        action: () => {
          this._bpmnReplace.replaceElement(element, {
            type: `mycustom:${task.type}`,
          })
        },
      }
    })

    return entries
  }

  createGatewayEntry(element, type, label, className) {
    const id = `replace-with-${type.split(":")[1].toLowerCase()}`
    return {
      [id]: {
        id,
        label,
        className,
        action: () => this._bpmnReplace.replaceElement(element, { type }),
      },
    }
  }
}

CustomReplaceMenuProvider.$inject = [
  "bpmnFactory",
  "popupMenu",
  "modeling",
  "moddle",
  "bpmnReplace",
  "rules",
  "translate",
]
