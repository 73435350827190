export const userRoles = Object.freeze({
  SubscriptionAdmin: "Subscription Admin",
  General: "General"
})

export const getToken = () => {
  let authData = localStorage.getItem("authUser")
  if (authData) {
    authData = JSON.parse(authData)
    return authData.token?.access_token
  }
  return undefined
}

export const getUser = () => {
  let authData = localStorage.getItem("authUser")
  if (authData) {
    authData = JSON.parse(authData)
    return authData.token
  }
  return undefined
}

export const getIdToken = () => {
  let authData = localStorage.getItem("authUser")
  if (!authData) {
    return undefined
  }

  authData = JSON.parse(authData)
  return authData.token?.id_token
}

export const isAuthedUser = () => {
  let authData = localStorage.getItem("authUser")
  if (authData) {
    return true
  }
  return false
}

export const saveStorage = token => {
  let authData = JSON.stringify({ token })
  localStorage.setItem("authUser", authData)
}

export const removeStorage = () => {
  localStorage.removeItem("authUser")
}

export const refreshStorage = user => {
  let authData = localStorage.getItem("authUser")
  if (authData) {
    authData = JSON.parse(authData)
    authData.user = user
    localStorage.setItem("authUser", JSON.stringify(authData))
  }
}

const mapCognitoGroupToUserRole = (group) => {
  switch (group) {
    case process.env.REACT_APP_GENERAL_USER_GROUP:
      return userRoles.General
    case process.env.REACT_APP_SUBSCRIPTION_ADMIN_USER_GROUP:
      return userRoles.SubscriptionAdmin
    default:
      return null
  }
}

export const getUserRoles = () => {
  const userIdToken = getIdToken()

  if (!userIdToken) {
    return []
  }

  let userPayloadBase64
  let userPayloadString
  let userPayload

  try {
    userPayloadBase64 = userIdToken.split(".")[1]
    userPayloadString = atob(userPayloadBase64)
    userPayload = JSON.parse(userPayloadString)
  } catch (error) {
    console.error(error)
    return []
  }

  
  const cognitoGroups = userPayload?.["cognito:groups"]

  if (!cognitoGroups || !Array.isArray(cognitoGroups)) {
    return []
  }
  
  return cognitoGroups.map(mapCognitoGroupToUserRole)
}

export const isSubscriptionAdminUser = () => {
 const userRolesData = getUserRoles()

 for (const userRole of userRolesData) {
    if (userRole === userRoles.SubscriptionAdmin) {
      return true
    }
 }
 
 return false
}
