import Breadcrumbs from "components/Common/Breadcrumb"
import { useCallback, useState } from "react"
import { Button, Col, Container, Input, Row, Spinner } from "reactstrap"
import { callGetApi, callPostApi } from "utils/api"

const Credits = () => {
  const [userSearchEmail, setUserSearchEmail] = useState("")
  const [store, setStore] = useState(process.env.REACT_APP_STORE)
  const [user, setUser] = useState(null)
  const [amountToAdd, setAmountToAdd] = useState(0)
  const [userLoading, setUserLoading] = useState(false)
  const [addingCredits, setAddingCredits] = useState(false)

  const bindUser = useCallback((e) => setUserSearchEmail(e.target.value))
  const bindStore = useCallback((e) => setStore(e.target.value))
  const bindAmountToAdd = useCallback((e) => setAmountToAdd(e.target.value))

  const refreshUser = useCallback(() => {
    callGetApi(
      true,
      `/credits/users?email=${encodeURIComponent(user.email)}&store=${user.store}`,
      (res) => {
        setUser(res)
      },
      (err) => {
        setUser(null)
        window.alert(err)
      }
    )
  }, [user])

  const searchUser = useCallback(() => {
    setUserLoading(true)

    callGetApi(
      true,
      `/credits/users?email=${encodeURIComponent(userSearchEmail)}&store=${store}`,
      (res) => {
        setUser(res)
        setUserLoading(false)
      },
      (err) => {
        setUser(null)
        setUserLoading(false)
        window.alert(err)
      }
    )
  })

  const addCredits = useCallback(() => {
    setAddingCredits(true)

    callPostApi(
      true,
      `/credits/users/${user.id}/movements/earnings`,
      { amount: parseInt(amountToAdd) },
      () => {
        setAddingCredits(false)
        refreshUser()
        window.alert("Credits added successfully")
      },
      (err) => {
        setAddingCredits(false)
        window.alert(err)
      }
    )
  })

  const UserContainer = ({ user }) => (
    <Row xs="1" lg="2" className="mt-4">
      <Col className="p-2">
        <h2>User information</h2>
        <Container fluid>
          <Row>
            <Col>
              <div className="fw-bold">First name</div>
              <div>{user.first_name}</div>
            </Col>
            <Col>
              <div className="fw-bold">Last name</div>
              <div>{user.last_name}</div>
            </Col>
          </Row>
          <Row className="mt-2">
            <div className="fw-bold">Email</div>
            <div>{user.email}</div>
          </Row>
          <Row className="mt-2">
            <Col>
              <div className="fw-bold">Current balance</div>
              <div>{user.balance}</div>
            </Col>
            <Col>
              <div className="fw-bold">Store</div>
              <div>{user.store}</div>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col className="p-2">
          <h2>Actions</h2>
          <Row>
            <Col><Input placeholder="Amount" type="number" min="1" value={amountToAdd} onInput={bindAmountToAdd}/></Col>
            <Col><Button block color="success" onClick={addCredits} disabled={addingCredits}>Add credits</Button></Col>
          </Row>
      </Col>
    </Row>
  )

  return (
    <div className="page-content">
      <Breadcrumbs title="Backoffice" breadcrumbItem="Credits" />
      <Container fluid>
        <Row xs="1" lg="3">
          <Col>
            <Input placeholder="Search user by email" value={userSearchEmail} onInput={bindUser} />
          </Col>
          <Col>
            <Input placeholder="Store" value={store} onInput={bindStore} disabled/>
          </Col>
          <Col>
          <Button block color="primary" onClick={searchUser} disabled={userLoading || !userSearchEmail}>Search</Button>
          </Col>
        </Row>
        { userLoading && <div className="text-center mt-4"><Spinner color="primary" /></div> }
        { !userLoading && user && <UserContainer user={user}/> }
      </Container>
    </div>
  )
}

export default Credits
