import AuthSecret from "pages/Authentication/AuthSecret"
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import Coupons from "pages/Coupons/index"
import DrawBpmnDiagrams from "pages/DrawFunnel/index"
import FunnelOfferDnd from "pages/ListFunnels/FunnelOfferDnd"
import ListFunnels from "pages/ListFunnels/index"
import ListPages from "pages/PageBuilder/List/index"
import AllProducts from "pages/Products/AllProducts"
import ProductPricing from "pages/Products/ProducPricing"
import SubscriptionTypes from "pages/SubscriptionTypes/index"
import Subscriptions from "pages/Subscriptions/index"
import Taxes from "pages/Taxes/index"
import EditOffer from "pages/Upsell/AddOffer/edit-offer"
import AddOffer from "pages/Upsell/AddOffer/index"
import AddAutoshipCriteria from "pages/Upsell/AddAutoshipCriteria/index"
import AllFunnels from "pages/Upsell/AllFunnels/index"
import CreateFunnel from "pages/Upsell/CreateFunnel/index"
import OfferDnd from "pages/Upsell/OfferDnd/index"
import ShopSelection from "pages/Upsell/ShopSelection/index"
import { Navigate } from "react-router-dom"
import { userRoles } from "utils/auth"
import Credits from "pages/Credits"


const pathRoutes = Object.freeze({
  Products: "/all-products",
  ProductDetailsById: "/product-details/:productId",
  Subscriptions: "/subscriptions",
  SubscriptionsTypes: "/subscription-types",
  Coupons: "/coupons",
  Taxes: "/taxes",
  AllFunnels: "/all-funnels",
  ShopSelection: "/shop-selection",
  CreateFunnel: "/create-funnel",
  AddOfferToFunnel: "/add-offer/:funnelId",
  AddAutoshipCriteriaToFunnel: "/add-autoship-criteria/:funnelId",
  EditOfferOnFunnelId: "/edit-offer/:funnelId/:offerId",
  Funnels: "/funnels",
  DrawFunnelOnMode: "/funnels/draw-your-funnel/:mode",
  DrawFunnelOnModeByFunnelId: "/funnels/draw-your-funnel/:mode/:funnelId",
  OfferDndByPageType: "/offer-dnd/:pageType",
  OfferDnd: "/offer-dnd/",
  PageBuildByFunnelId: "/page-builder/:funnelId",
  PagesByFunnelId: "/funnels/:funnelId/pages",
  Credits: "/credits",
  Home: "/"
})

const generalRoleRoutes = Object.freeze({
  [pathRoutes.Products]: true,
  [pathRoutes.ProductDetailsById]: true,
  [pathRoutes.Coupons]: true,
  [pathRoutes.Taxes]: true,
  [pathRoutes.AllFunnels]: true,
  [pathRoutes.ShopSelection]: true,
  [pathRoutes.CreateFunnel]: true,
  [pathRoutes.AddOfferToFunnel]: true,
  [pathRoutes.AddAutoshipCriteriaToFunnel]: true,
  [pathRoutes.EditOfferOnFunnelId]: true,
  [pathRoutes.Funnels]: true,
  [pathRoutes.DrawFunnelOnMode]: true,
  [pathRoutes.DrawFunnelOnModeByFunnelId]: true,
  [pathRoutes.OfferDndByPageType]: true,
  [pathRoutes.OfferDnd]: true,
  [pathRoutes.PageBuildByFunnelId]: true,
  [pathRoutes.PagesByFunnelId]: true,
  [pathRoutes.Credits]: true,
  [pathRoutes.Home]: true,
})

const routesByRole = Object.freeze({
  [userRoles.SubscriptionAdmin]: {
    ...generalRoleRoutes,
    [pathRoutes.Subscriptions]: true,
    [pathRoutes.SubscriptionsTypes]: true,
  },
  [userRoles.General]: {
    ...generalRoleRoutes
  }
})

const authProtectedRoutes = [
  { path: pathRoutes.Products, component: <AllProducts /> },
  { path: pathRoutes.ProductDetailsById, component: <ProductPricing /> },
  { path: pathRoutes.Subscriptions, component: <Subscriptions /> },
  { path: pathRoutes.SubscriptionsTypes, component: <SubscriptionTypes /> },
  { path: pathRoutes.Coupons, component: <Coupons /> },
  { path: pathRoutes.Taxes, component: <Taxes /> },
  { path: pathRoutes.AllFunnels, component: <AllFunnels /> },
  { path: pathRoutes.ShopSelection, component: <ShopSelection /> },
  { path: pathRoutes.CreateFunnel, component: <CreateFunnel /> },
  { path: pathRoutes.AddOfferToFunnel, component: <AddOffer /> },
  { path: pathRoutes.AddAutoshipCriteriaToFunnel, component: <AddAutoshipCriteria /> },
  { path: pathRoutes.EditOfferOnFunnelId, component: <EditOffer /> },
  { path: pathRoutes.Funnels, component: <ListFunnels /> },
  { path: pathRoutes.DrawFunnelOnMode, component: <DrawBpmnDiagrams />, },
  { path: pathRoutes.DrawFunnelOnModeByFunnelId, component: <DrawBpmnDiagrams />, },
  { path: pathRoutes.OfferDndByPageType, component: <OfferDnd /> },
  { path: pathRoutes.OfferDnd, component: <OfferDnd /> },
  { path: pathRoutes.PageBuildByFunnelId, component: <FunnelOfferDnd /> },
  { path: pathRoutes.PagesByFunnelId, component: <ListPages /> },
  { path: pathRoutes.Credits, component: <Credits /> },
  { path: pathRoutes.Home, exact: true, component: <Navigate to="/all-funnels" /> },
]

const publicRoutes = [
  { path: "/auth-secret", component: <AuthSecret /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
]

export { authProtectedRoutes, publicRoutes, routesByRole, pathRoutes }
