import React, { Component } from "react"
import "../../assets/scss/custom/components/_properties-view.scss"
import { toTitleCaseWithSpacing } from "../../utils/bpmn"
import { is } from "bpmn-js/lib/util/ModelUtil"

const customTypesFields = [
  { name: "name", type: "input" },
  { name: "url", type: "input" },
]

const INITIAL_STATE = { element: null, position: { x: 0, y: 0 }, gatewayOptions: ['N/A'] }

const CRITERIA_OPTIONS = [
  "equal",
  "not equal",
  "greater",
  "less",
  "greater or equal",
  "less or equal",
]

class PropertiesView extends Component {
  state = INITIAL_STATE
  customElementProperties = {
    "mycustom:quiz": [
      ...customTypesFields,
      { name: "identifier", type: "input" },
    ],
    "mycustom:offer": customTypesFields,
    "mycustom:cross_sell": customTypesFields,

    "mycustom:metrics": [
      { name: "funnelId", type: "input" },
      { name: "description", type: "input" },
    ],
    "mycustom:popup": [{ name: "type", type: "input" }],
    "bpmn:ExclusiveGateway": [{ name: "field", type: "select", options: [] }],
    "bpmn:SequenceFlow": [
      { name: "value", type: "input" },
      {
        name: "criteria",
        type: "select",
        options: CRITERIA_OPTIONS,
      },
    ],
  }

  componentDidMount() {
    const { modeler } = this.props
    this.subscribeToModeler(modeler)
  }

  componentDidUpdate(prevProps) {
    const { modeler } = this.props
    if (prevProps.modeler !== modeler) {
      this.subscribeToModeler(modeler)
    }
  }

  subscribeToModeler(modeler) {
    modeler.on("selection.changed", e => this.handleSelectionChanged(e, modeler))
    modeler.on("element.changed", e => this.setElement(e.element))
  }

  updateElementPosition(element, modeler) {
    this.setElement(element)
    if (element) {
      const { x, y } =
        element.x != null || element.y != null
          ? element
          : element.waypoints[0]
      const canvas = modeler.get("canvas")
      const zoom = canvas.zoom(false)
      const scroll = canvas.scroll()
      const pan = scroll ? scroll : { x: 0, y: 0 }
      const transformedX = (x + pan.x) * zoom
      const transformedY = (y + pan.y) * zoom

      const offsetX = -280
      const offsetY = 160

      const xPosition = transformedX + offsetX
      this.setState({
        position: {
          x: xPosition < 0 ? 0 : xPosition,
          y: transformedY + offsetY,
        },
      })
    }
  }

  handleSelectionChanged(e, modeler) {
    const element = e.newSelection[0]
    this.updateElementPosition(element, modeler)
    this.updateGatewayOptions(modeler)
  }

  setElement(element) {
    if (
      !element ||
      (is(element, "bpmn:SequenceFlow") &&
        !is(element.source, "bpmn:ExclusiveGateway"))
    ) {
      this.setState(INITIAL_STATE)
      return
    }

    if (
      is(element, "bpmn:SequenceFlow") &&
      !element.businessObject["criteria"]
    ) {
      element.businessObject["criteria"] = CRITERIA_OPTIONS[0]
    }

    this.setState({ element })
  }

  updateGatewayOptions(modeler) {
    const elementRegistry = modeler.get('elementRegistry')
    const quizzes = elementRegistry.filter(el => is(el, 'mycustom:quiz'))
    const quizNames = quizzes.map(quiz => quiz.businessObject.name)

    const options = quizNames.length > 0 ? quizNames : ['N/A']
    this.setState({ gatewayOptions: options })
  }

  handleInputChange(propertyName, event) {
    const value = event.target.value
    this.setState(prevState => {
      const { element } = prevState

      element.businessObject[propertyName] = this.props.canEdit
        ? value
        : element.businessObject[propertyName]
      this.props.modeler.get("eventBus").fire("element.changed", { element })

      return {
        ...prevState,
        element: {
          ...element,
          businessObject: {
            ...element.businessObject,
            [propertyName]: value,
          },
        },
      }
    })
  }

  renderCustomProperties() {
    const { element } = this.state

    if (element && element.type in this.customElementProperties) {
      const elementTypeProperties = this.customElementProperties[element.type]

      const propertiesToRender = Object.values(elementTypeProperties)

      return propertiesToRender.map(control => (
        <fieldset key={control.name}>
          <label>{toTitleCaseWithSpacing(control.name)}</label>
          {control.type === "input" ? (
            <input
              type="text"
              value={element.businessObject[control.name] || ""}
              onChange={e => this.handleInputChange(control.name, e)}
            />
          ) : null}
          {control.type === "select" ? (
            <select
              value={element.businessObject[control.name] || ""}
              onChange={e => this.handleInputChange(control.name, e)}
              className="form-control form-control-sm"
            >
              {(is(element, "bpmn:ExclusiveGateway") ? this.state.gatewayOptions : CRITERIA_OPTIONS).map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          ) : null}
        </fieldset>
      ))
    }

    return null
  }

  render() {
    const { element, position } = this.state

    if (!element || !(element.type in this.customElementProperties)) {
      return null
    }

    const style = {
      left: `${position.x}px`,
      top: `${position.y}px`,
      zIndex: 1000,
    }

    return (
      <div style={style} className="properties-view-container">
        {this.renderCustomProperties()}
      </div>
    )
  }
}

export default PropertiesView
