import React, { useState } from "react"

import { Container, Modal } from "reactstrap"
import { FileUploader } from "react-drag-drop-files"

import { showSuccessToastr } from "components/Common/toastr"
import { showErrorToastr } from "components/Common/toastr"
import { Label, Input } from "reactstrap"
import { callPostApi } from "utils/api"
import csvIcon from "assets/images/csv.png"
import { initialShopEnv, allShopsEnv } from "../../../utils/shop"

import "./index.scss"

const UploadModal = ({ isOpen, setOpen, loadTaxes }) => {
  const fileTypes = ["CSV"]
  const [file, setFile] = useState(null)
  const [country, setCountry] = useState("US")
  const [shop, setShop] = useState(initialShopEnv[process.env.REACT_APP_ENV])
  const [productType, setProductType] = useState("all")
  const [state, setState] = useState("")
  const handleChange = file => {
    setFile(file)
  }

  const fileSizeText = bytes => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    if (bytes === 0) return "0 Byte"
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i]
  }

  const handleSaveTax = () => {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("country", country)
    formData.append("store", shop)
    formData.append("product_type", productType)
    formData.append("state", state)

    callPostApi(
      true,
      "/taxes/upload",
      formData,
      () => {
        setFile(null)
        showSuccessToastr("CSV uploaded succesfully!")
        setOpen(false)
        setState("")
        setShop(initialShopEnv[process.env.REACT_APP_ENV])
        loadTaxes("")
      },
      () => {
        showErrorToastr("Something went wrong!")
      },
      undefined,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  }

  return (
    <Modal isOpen={isOpen} size="m" className="upload-modal">
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Upload CSV File
        </h5>

        <button
          type="button"
          onClick={() => {
            setOpen(false)
            setFile(null)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body page-content create-tax pt-3 pb-3">
        <Container fluid={true}>
          <div>
            <Label htmlFor="formrow-country-Input" className="mb-1">
              Country Code
            </Label>
            <Input
              name="country"
              className="form-control country-input"
              id="formrow-country-Input"
              required
              disabled
              value={country}
              onChange={e => setCountry(e.target.value.toLocaleUpperCase())}
              maxLength={3}
            ></Input>
          </div>
          <div>
            <Label htmlFor="formrow-country-Input" className="mb-1">
              Store
            </Label>
            <select
              className="form-control country-input"
              name="shop"
              onChange={e => setShop(e.target.value)}
              value={shop}
            >
              {allShopsEnv[process.env.REACT_APP_ENV].map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <Label htmlFor="formrow-country-Input" className="mb-1">
              Product Type
            </Label>
            <select
              className="form-control country-input"
              name="productType"
              onChange={e => setProductType(e.target.value)}
              value={productType}
            >
              <option value="all">All</option>
            </select>
          </div>
          <div>
            <Label htmlFor="formrow-country-Input" className="mb-1">
              State Code
            </Label>
            <Input
              name="state"
              className="form-control country-input"
              id="formrow-country-Input"
              value={state}
              onChange={e => setState(e.target.value.toLocaleUpperCase())}
              maxLength={3}
            ></Input>
          </div>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            style={{ width: "100%", height: "100%" }}
            multiple={false}
          >
            <div id="uploader-box">
              <h4>
                <i className="mdi mdi-cloud-upload" style={{ fontSize: 45 }} />
              </h4>
              <h3>Drop file here or click to upload</h3>
            </div>
          </FileUploader>
          {file && (
            <div className="icon-wrapper">
              <img className="icon" src={csvIcon} alt="csv-icon" />
              <div className="file-name">
                <p>
                  {file.name}
                  <br />
                  <b>{fileSizeText(file.size)}</b>
                </p>
              </div>
            </div>
          )}
        </Container>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setOpen(false)
            setCountry("")
            setFile(null)
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSaveTax}
          disabled={!file || !country}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default UploadModal
