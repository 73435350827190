import moment from "moment-timezone"

const DATE_FORMATS = {
  short: "YYYY-MM-DD", long: "YYYY-MM-DD HH:mm:ss"
}

export function getFormattedDate(value, formatType) {
  if (!value) {
    return value
  }

  const format = DATE_FORMATS[formatType]

  if (!format) {
    throw new Error(`Invalid format type: ${formatType}`)
  }

  return moment.utc(value).format(format)
}